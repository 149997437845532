import ProductCategories from "../component/ProductCategories";

export function filter(searchString, searchables) {
  // Convert searchString to lowercase to ensure case-insensitive filtering
  const normalizedSearchString = searchString.toLowerCase();

  // Filter the list based on whether the item starts with the searchString
  const filteredResults = searchables.filter(item => item.name.toLowerCase().startsWith(normalizedSearchString));
  console.log('console.log(filteredResults);');
  console.log(filteredResults);
  return filteredResults;
}

// data.js
export function getData(language) {
    const dataMap = {
      english: {
        frequentlyVisitedCommunities: [
          { people: 'Mexican', flag: 'mx', url: '/mexican' },
          { people: 'American', flag: 'us', url: '/american' },
          { people: 'African', flag: 'af', url: '/african' },
        ],
         communities : [
          { people: 'American', flag: 'us', url: '/american' },
          { people: 'Algerian', flag: 'dz' },
          { people: 'African', flag: 'af', url: '/african' },
          { people: 'Argentinian', flag: 'ar' },
          { people: 'Australian', flag: 'au' },
          { people: 'British', flag: 'gb' },
          { people: 'Chinese', flag: 'cn' },
          { people: 'Ethiopian', flag: 'et' },
          { people: 'Hawaiian', flag: 'us' },
          { people: 'Indian', flag: 'in' },
          { people: 'Mexican', flag: 'mx', url: '/mexican' },
          { people: 'Jamaican', flag: 'jm' },
          { people: 'Lebanese', flag: 'lb' },
          { people: 'Nepali', flag: 'np' },
          { people: 'Nigerian', flag: 'ng' },
          { people: 'Thai', flag: 'th' },
        ],
        categories: [
          { name: 'Grocery stores', icon: '🛒', url: '/your-store' },
          { name: 'Boutique', icon: '👜' },
          { name: 'IT Services', icon: '💻' },
          { name: 'Home Help', icon: '🏡' },
          { name: 'Realtor', icon: '🏘️' },
          { name: 'Transportation', icon: '🚗' },
          { name: 'Legal', icon: '⚖️' },
          { name: 'Education', icon: '📚' },
          { name: 'Health', icon: '⚕️' },
          { name: 'Student Services', icon: '🎓' },
          { name: 'Beauty', icon: '💄' },
          { name: 'Tailor', icon: '👗' },
          { name: 'Venues', icon: '🏢' },
          { name: 'Photography', icon: '📷' },
          { name: 'Event Management', icon: '🎉' },
          { name: 'Jewelry', icon: '💍' },
          { name: 'Food Truck', icon: '🚚' },
          { name: 'Restaurants', icon: '🍴' },
        ],
      },
      ethiopian: {
        frequentlyVisitedCommunities: [
          { people: 'ሜክሲካን', flag: 'mx', url: '/mexican' },
          { people: 'አሜሪካዊ', flag: 'us', url: '/american' },
          { people: 'አፍሪካዊ', flag: 'af', url: '/african' },
        ],
        communities: [
          { people: 'አሜሪካዊ', flag: 'us', url: '/american' },
          { people: 'አልጀሪኛ', flag: 'dz' },
          { people: 'አፍሪካዊ', flag: 'af', url: '/african' },
          { people: 'አርጀንቲኛ', flag: 'ar' },
          { people: 'አውስትራሊያኛ', flag: 'au' },
          { people: 'ብሪቲሽ', flag: 'gb' },
          { people: 'ቻይንኛ', flag: 'cn' },
          { people: 'ኢትዮጵያዊ', flag: 'et' },
          { people: 'ሃዋያን', flag: 'us' },
          { people: 'ህንዳዊ', flag: 'in' },
          { people: 'ሜክሲካን', flag: 'mx', url: '/mexican' },
          { people: 'ጃማይካዊ', flag: 'jm' },
          { people: 'ሊባንኛ', flag: 'lb' },
          { people: 'ኔፓሊዛዊ', flag: 'np' },
          { people: 'ናይጄሪያዊ', flag: 'ng' },
          { people: 'ታይ', flag: 'th' },
        ],
        categories:
        [
          { name: 'ነጋዴ መደብር', icon: '🛒', url: '/your-store' },
          { name: 'ቡቲክ', icon: '👜' },
          { name: 'አይቲ አገልግሎቶች', icon: '💻' },
          { name: 'የቤት እርዳታ', icon: '🏡' },
          { name: 'የንብረት አካቢያች', icon: '🏘️' },
          { name: 'መጓጓዣ', icon: '🚗' },
          { name: 'የህግ አገልግሎቶች', icon: '⚖️' },
          { name: 'ትምህርት', icon: '📚' },
          { name: 'ጤና', icon: '⚕️' },
          { name: 'የተማሪ አገልግሎቶች', icon: '🎓' },
          { name: 'ውበት', icon: '💄' },
          { name: 'ታይሎር', icon: '👗' },
          { name: 'የቦታዎች እንቅስቃሴ', icon: '🏢' },
          { name: 'ፎቶግራፍ', icon: '📷' },
          { name: 'የክስተት አስተዳደር', icon: '🎉' },
          { name: 'ጌጣጌጥ', icon: '💍' },
          { name: 'የምግብ መኪና', icon: '🚚' },
          { name: 'ሬስቶራንቶች', icon: '🍴' },
        ],
        
      },
        arabic: {
          frequentlyVisitedCommunities: [
            { people: 'مكسيكي', flag: 'mx', url: '/mexican' },
            { people: 'أمريكي', flag: 'us', url: '/american' },
            { people: 'أفريقي', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'أمريكي', flag: 'us', url: '/american' },
            { people: 'جزائري', flag: 'dz' },
            { people: 'أفريقي', flag: 'af', url: '/african' },
            { people: 'أرجنتيني', flag: 'ar' },
            { people: 'أسترالي', flag: 'au' },
            { people: 'بريطاني', flag: 'gb' },
            { people: 'صيني', flag: 'cn' },
            { people: 'إثيوبي', flag: 'et' },
            { people: 'هاوايي', flag: 'us' },
            { people: 'هندي', flag: 'in' },
            { people: 'مكسيكي', flag: 'mx', url: '/mexican' },
            { people: 'جامايكي', flag: 'jm' },
            { people: 'لبناني', flag: 'lb' },
            { people: 'نيبالي', flag: 'np' },
            { people: 'نيجيري', flag: 'ng' },
            { people: 'تايلاندي', flag: 'th' },
          ],

          categories:[
            { name: 'محلات البقالة', icon: '🛒', url: '/your-store' },
            { name: 'بوتيك', icon: '👜' },
            { name: 'خدمات تكنولوجيا المعلومات', icon: '💻' },
            { name: 'مساعدة منزلية', icon: '🏡' },
            { name: 'وسيط عقارات', icon: '🏘️' },
            { name: 'النقل', icon: '🚗' },
            { name: 'قانوني', icon: '⚖️' },
            { name: 'التعليم', icon: '📚' },
            { name: 'الصحة', icon: '⚕️' },
            { name: 'خدمات الطلاب', icon: '🎓' },
            { name: 'الجمال', icon: '💄' },
            { name: 'الخياط', icon: '👗' },
            { name: 'أماكن الفعاليات', icon: '🏢' },
            { name: 'التصوير الفوتوغرافي', icon: '📷' },
            { name: 'إدارة الأحداث', icon: '🎉' },
            { name: 'المجوهرات', icon: '💍' },
            { name: 'شاحنة طعام', icon: '🚚' },
            { name: 'المطاعم', icon: '🍴' },
          ],
          
        },
        chinese: {
          frequentlyVisitedCommunities: [
            { people: '墨西哥人', flag: 'mx', url: '/mexican' },
            { people: '美国人', flag: 'us', url: '/american' },
            { people: '非洲人', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: '美国人', flag: 'us', url: '/american' },
            { people: '阿尔及利亚人', flag: 'dz' },
            { people: '非洲人', flag: 'af', url: '/african' },
            { people: '阿根廷人', flag: 'ar' },
            { people: '澳大利亚人', flag: 'au' },
            { people: '英国人', flag: 'gb' },
            { people: '中国人', flag: 'cn' },
            { people: '埃塞俄比亚人', flag: 'et' },
            { people: '夏威夷人', flag: 'us' },
            { people: '印度人', flag: 'in' },
            { people: '墨西哥人', flag: 'mx', url: '/mexican' },
            { people: '牙买加人', flag: 'jm' },
            { people: '黎巴嫩人', flag: 'lb' },
            { people: '尼泊尔人', flag: 'np' },
            { people: '尼日利亚人', flag: 'ng' },
            { people: '泰国人', flag: 'th' },
          ],

          categories:[
            { name: '杂货店', icon: '🛒', url: '/your-store' },
            { name: '精品店', icon: '👜' },
            { name: 'IT服务', icon: '💻' },
            { name: '家庭帮助', icon: '🏡' },
            { name: '房地产经纪人', icon: '🏘️' },
            { name: '交通运输', icon: '🚗' },
            { name: '法律服务', icon: '⚖️' },
            { name: '教育', icon: '📚' },
            { name: '健康', icon: '⚕️' },
            { name: '学生服务', icon: '🎓' },
            { name: '美容', icon: '💄' },
            { name: '裁缝', icon: '👗' },
            { name: '活动场所', icon: '🏢' },
            { name: '摄影', icon: '📷' },
            { name: '活动管理', icon: '🎉' },
            { name: '珠宝', icon: '💍' },
            { name: '餐车', icon: '🚚' },
            { name: '餐馆', icon: '🍴' },
          ],
          
        },
        spanish: {
          frequentlyVisitedCommunities: [
            { people: 'Mexicano', flag: 'mx', url: '/mexican' },
            { people: 'Americano', flag: 'us', url: '/american' },
            { people: 'Africano', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'Americano', flag: 'us', url: '/american' },
            { people: 'Argelino', flag: 'dz' },
            { people: 'Africano', flag: 'af', url: '/african' },
            { people: 'Argentino', flag: 'ar' },
            { people: 'Australiano', flag: 'au' },
            { people: 'Británico', flag: 'gb' },
            { people: 'Chino', flag: 'cn' },
            { people: 'Etíope', flag: 'et' },
            { people: 'Hawaiano', flag: 'us' },
            { people: 'Indio', flag: 'in' },
            { people: 'Mexicano', flag: 'mx', url: '/mexican' },
            { people: 'Jamaicano', flag: 'jm' },
            { people: 'Libanés', flag: 'lb' },
            { people: 'Nepalí', flag: 'np' },
            { people: 'Nigeriano', flag: 'ng' },
            { people: 'Tailandés', flag: 'th' },
          ],

          categories:[
            { name: 'Tiendas de comestibles', icon: '🛒', url: '/your-store' },
            { name: 'Boutique', icon: '👜' },
            { name: 'Servicios de TI', icon: '💻' },
            { name: 'Ayuda en casa', icon: '🏡' },
            { name: 'Agente inmobiliario', icon: '🏘️' },
            { name: 'Transporte', icon: '🚗' },
            { name: 'Legal', icon: '⚖️' },
            { name: 'Educación', icon: '📚' },
            { name: 'Salud', icon: '⚕️' },
            { name: 'Servicios para estudiantes', icon: '🎓' },
            { name: 'Belleza', icon: '💄' },
            { name: 'Sastre', icon: '👗' },
            { name: 'Lugares', icon: '🏢' },
            { name: 'Fotografía', icon: '📷' },
            { name: 'Gestión de eventos', icon: '🎉' },
            { name: 'Joyería', icon: '💍' },
            { name: 'Camión de comida', icon: '🚚' },
            { name: 'Restaurantes', icon: '🍴' },
          ],
          
        },
        hindi: {
          frequentlyVisitedCommunities: [
            { people: 'मेक्सिकन', flag: 'mx', url: '/mexican' },
            { people: 'अमेरिकन', flag: 'us', url: '/american' },
            { people: 'अफ्रीकी', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'अमेरिकन', flag: 'us', url: '/american' },
            { people: 'अल्जीरियन', flag: 'dz' },
            { people: 'अफ्रीकी', flag: 'af', url: '/african' },
            { people: 'अर्जेंटीनी', flag: 'ar' },
            { people: 'ऑस्ट्रेलियाई', flag: 'au' },
            { people: 'ब्रिटिश', flag: 'gb' },
            { people: 'चीनी', flag: 'cn' },
            { people: 'इथियोपियाई', flag: 'et' },
            { people: 'हवाईयन', flag: 'us' },
            { people: 'भारतीय', flag: 'in' },
            { people: 'मेक्सिकन', flag: 'mx', url: '/mexican' },
            { people: 'जमैका', flag: 'jm' },
            { people: 'लेबनानी', flag: 'lb' },
            { people: 'नेपाली', flag: 'np' },
            { people: 'नाइजीरियाई', flag: 'ng' },
            { people: 'थाई', flag: 'th' },
          ],

          categories:[
            { name: 'किराना दुकानें', icon: '🛒', url: '/your-store' },
            { name: 'बुटीक', icon: '👜' },
            { name: 'आईटी सेवाएं', icon: '💻' },
            { name: 'गृह सहायता', icon: '🏡' },
            { name: 'रियल्टर', icon: '🏘️' },
            { name: 'परिवहन', icon: '🚗' },
            { name: 'कानूनी', icon: '⚖️' },
            { name: 'शिक्षा', icon: '📚' },
            { name: 'स्वास्थ्य', icon: '⚕️' },
            { name: 'छात्र सेवाएं', icon: '🎓' },
            { name: 'सौंदर्य', icon: '💄' },
            { name: 'दर्जी', icon: '👗' },
            { name: 'स्थल', icon: '🏢' },
            { name: 'फोटोग्राफी', icon: '📷' },
            { name: 'इवेंट प्रबंधन', icon: '🎉' },
            { name: 'आभूषण', icon: '💍' },
            { name: 'फूड ट्रक', icon: '🚚' },
            { name: 'रेस्टोरेंट', icon: '🍴' },
          ],
          
        },
        bengali: {
          frequentlyVisitedCommunities: [
            { people: 'মেক্সিকান', flag: 'mx', url: '/mexican' },
            { people: 'আমেরিকান', flag: 'us', url: '/american' },
            { people: 'আফ্রিকান', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'আমেরিকান', flag: 'us', url: '/american' },
            { people: 'আলজেরিয়ান', flag: 'dz' },
            { people: 'আফ্রিকান', flag: 'af', url: '/african' },
            { people: 'আর্জেন্টাইন', flag: 'ar' },
            { people: 'অস্ট্রেলিয়ান', flag: 'au' },
            { people: 'ব্রিটিশ', flag: 'gb' },
            { people: 'চীনা', flag: 'cn' },
            { people: 'ইথিওপিয়ান', flag: 'et' },
            { people: 'হাওয়াইয়ান', flag: 'us' },
            { people: 'ভারতীয়', flag: 'in' },
            { people: 'মেক্সিকান', flag: 'mx', url: '/mexican' },
            { people: 'জ্যামাইকান', flag: 'jm' },
            { people: 'লেবানিজ', flag: 'lb' },
            { people: 'নেপালি', flag: 'np' },
            { people: 'নাইজেরিয়ান', flag: 'ng' },
            { people: 'থাই', flag: 'th' },
          ],

          categories:[
            { name: 'মুদির দোকান', icon: '🛒', url: '/your-store' },
            { name: 'বুটিক', icon: '👜' },
            { name: 'আইটি পরিষেবা', icon: '💻' },
            { name: 'গৃহ সাহায্য', icon: '🏡' },
            { name: 'রিয়েলটর', icon: '🏘️' },
            { name: 'পরিবহন', icon: '🚗' },
            { name: 'আইনগত', icon: '⚖️' },
            { name: 'শিক্ষা', icon: '📚' },
            { name: 'স্বাস্থ্য', icon: '⚕️' },
            { name: 'ছাত্র পরিষেবা', icon: '🎓' },
            { name: 'সৌন্দর্য', icon: '💄' },
            { name: 'দর্জি', icon: '👗' },
            { name: 'স্থান', icon: '🏢' },
            { name: 'ফটোগ্রাফি', icon: '📷' },
            { name: 'ইভেন্ট ব্যবস্থাপনা', icon: '🎉' },
            { name: 'গয়না', icon: '💍' },
            { name: 'খাবারের ট্রাক', icon: '🚚' },
            { name: 'রেস্টুরেন্ট', icon: '🍴' },
          ],
          
        },
        swahili: {
          frequentlyVisitedCommunities: [
            { people: 'Meksikani', flag: 'mx', url: '/mexican' },
            { people: 'Mmarekani', flag: 'us', url: '/american' },
            { people: 'Mwafrika', flag: 'af', url: '/african' },
          ],
          communities: [
            { people: 'Mmarekani', flag: 'us', url: '/american' },
            { people: 'Mualjeria', flag: 'dz' },
            { people: 'Mwafrika', flag: 'af', url: '/african' },
            { people: 'Magentina', flag: 'ar' },
            { people: 'Muaustralia', flag: 'au' },
            { people: 'Mwingereza', flag: 'gb' },
            { people: 'Mchina', flag: 'cn' },
            { people: 'Mwethiopia', flag: 'et' },
            { people: 'Mhawaia', flag: 'us' },
            { people: 'Mhindi', flag: 'in' },
            { people: 'Meksikani', flag: 'mx', url: '/mexican' },
            { people: 'Mjamaika', flag: 'jm' },
            { people: 'Mlebanoni', flag: 'lb' },
            { people: 'Mnepali', flag: 'np' },
            { people: 'Mnigeria', flag: 'ng' },
            { people: 'Mthailand', flag: 'th' },
          ],
          categories: [
            { name: 'Maduka ya Vyakula', icon: '🛒', url: '/your-store' },
            { name: 'Duka la Mitindo', icon: '👜' },
            { name: 'Huduma za IT', icon: '💻' },
            { name: 'Msaada wa Nyumbani', icon: '🏡' },
            { name: 'Dalali wa Nyumba', icon: '🏘️' },
            { name: 'Usafiri', icon: '🚗' },
            { name: 'Huduma za Kisheria', icon: '⚖️' },
            { name: 'Elimu', icon: '📚' },
            { name: 'Afya', icon: '⚕️' },
            { name: 'Huduma za Wanafunzi', icon: '🎓' },
            { name: 'Urembo', icon: '💄' },
            { name: 'Mshonaji', icon: '👗' },
            { name: 'Kumbi', icon: '🏢' },
            { name: 'Upigaji Picha', icon: '📷' },
            { name: 'Usimamizi wa Matukio', icon: '🎉' },
            { name: 'Vito vya Thamani', icon: '💍' },
            { name: 'Gari la Chakula', icon: '🚚' },
            { name: 'Mikahawa', icon: '🍴' },
          ],
        },
        
        
                        
      
    };
  

    //return based on language
    return dataMap[language] || dataMap.english; // Default to English if language not found
  }


  