import React, { useState } from 'react';
import Modal from 'react-modal';
import { auth, signInWithEmailAndPassword } from '../../firebase'; // Ensure proper import
import './SignInModal.css'; // Import the CSS file

Modal.setAppElement('#root');

const SignInModal = ({ isOpen, onClose, onSignInSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const maxPasswordLength = 20;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      onSignInSuccess(userCredential.user.email); // Pass user email on success
      onClose(); // Close modal
    } catch (error) {
      setError(error.message); // Set error message
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Sign In"
      className="small-modal"
    >
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email or Username:</label>
        <input
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="password">Password:</label>
        <input
          id="password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          maxLength={maxPasswordLength}
          required
        />
        <div className="character-count">
          {maxPasswordLength - password.length} characters left
        </div>
        <div className="show-password-container">
          <input
            id="show-password"
            type="checkbox"
            checked={showPassword}
            onChange={toggleShowPassword}
          />
          <label htmlFor="show-password">Show Password</label>
        </div>
        <button type="submit" className="primary-button">Sign In</button>
        <button type="button" onClick={onClose} className="close-button">Close</button>
      </form>
      {error && <p className="error-message">{error}</p>}
      <div className="forgot-section">
        <button
          className="link-button"
          onClick={() => {
            // Logic for Forgot Password (to be implemented)
            alert('Forgot Password logic not implemented');
          }}
        >
          Forgot Password?
        </button>
        <button
          className="link-button"
          onClick={() => {
            // Logic for Forgot Username (to be implemented)
            alert('Forgot Username logic not implemented');
          }}
        >
          Forgot Username?
        </button>
      </div>
    </Modal>
  );
};

export default SignInModal;
