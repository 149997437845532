import React, { useState } from 'react'; 
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import './Menu.css';
import Header from './goourhub/component/Header';
import Footer from './goourhub/component/Footer';
import YourStore from './goourhub/component/YourStore';
import './NewTile.css';
import './goourhub/main/aligners.css';
import 'flag-icons/css/flag-icons.min.css'; // Import flag icons
import ProductCategories from './goourhub/component/ProductCategories';
import ServiceCategories from './goourhub/component/ServiceCategories';
import ImportantLinks from './goourhub/component/ImportantLinks';
import Template from './goourhub/main/template';
import CommunityConnect from './goourhub/component/CommunityConnect';

import TransitioningSectionForAd from './goourhub/component/TransitioningSectionForAd';
import VendorTemplate1 from './goourhub/component/VendorTemplate1'; 
import VendorTemplate2 from './goourhub/component/VendorTemplate2'; 
import VendorTemplate3 from './goourhub/component/VendorTemplate3'; 
import ContactUs from './goourhub/component/ContactUs';
import SignInModal from './goourhub/component/SignInModal';
import SignUpModal from './goourhub/component/SignUpModal';
import Mexican from './goourhub/component/Mexican';
import American from './goourhub/component/American';
import African from './goourhub/component/African';
import AboutUs from './goourhub/component/AboutUs';
import CommunityForum from './goourhub/component/CommunityForum';
import SliderForMobile from './goourhub/component/SliderForMobile';
import LanguageDropdown from './goourhub/component/LanguageDropdown';
import ReviewSystem from './goourhub/component/ReviewSystem';
import UserDashboard from './goourhub/component/UserDashboard';
import { getData, filter } from './goourhub/data/data';

function GoHubApp() {
  const [language, setLanguage] = useState("english"); // Default language
  const { frequentlyVisitedCommunities, communities, categories } = getData(language);
  const [filteredResults, setFilteredResults] = useState(categories); // Manage filtered data

  const handleSearch = (searchQuery) => {
    const results = filter(searchQuery, categories); // Filter searchables
    setFilteredResults(results); // Update the state
  };

  return (
    <Router>
      <div className="App">
        <Template>
          {/* Header Section */}
          <div className="headerSection">
            <Header onSearch={handleSearch} />
            <nav>
             
            </nav>
          </div>

          {/* Left Menu Section */}
          <div className="menuSection">
            <div className="menu-section">
              <LanguageDropdown selectedLanguage={language} onLanguageChange={setLanguage} />
              <span style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Community Hub</span>
              <CommunityConnect countries={communities} frequentlyVisited={frequentlyVisitedCommunities} />
              <SliderForMobile countries={[...frequentlyVisitedCommunities, ...communities]} />
            </div>
          </div>

          {/* Main Section */}
          <div className="mainSection">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <ProductCategories categories={filteredResults} />
                    <div className="new-tile-container-seperator"></div>
                    <ServiceCategories />
                    <div className="new-tile-container-seperator"></div>
                  </>
                }
              />
              <Route path="/your-store" element={<YourStore />} />
              <Route path="/vendor-template1" element={<VendorTemplate1 />} />
              <Route path="/vendor-template2" element={<VendorTemplate2 />} />
              <Route path="/vendor-template3" element={<VendorTemplate3 />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/SignInModal" element={<SignInModal />} />
              <Route path="/SignUpModal" element={<SignUpModal />} />
              <Route path="/mexican" element={<Mexican />} />
              <Route path="/american" element={<American />} />
              <Route path="/african" element={<African />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/review-system" element={<ReviewSystem />} />
              <Route path="/forum" element={<CommunityForum />} />
              <Route path="/dashboard" element={<UserDashboard />} /> {/* Dashboard route */}
            </Routes>
          </div>

          {/* Right Navigation Section */}
          <div className="rightNavSection">
            <ImportantLinks />
            <TransitioningSectionForAd />
          </div>

          {/* Footer Section */}
          <Footer />
        </Template>
      </div>
    </Router>
  );
}

export default GoHubApp;
