import React from "react";
import "./VendorTemplate3.css";

function VendorTemplate3() {
  return (
    <div className="vendor-template3-container">
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Welcome to YourHub's Resturant</h1>
        <p>Explore handcrafted products with a story behind every piece</p>
      </div>

      {/* About Section */}
      <div className="about-section">
        <h2>About Us</h2>
        <p>
          Our products are crafted with love and attention to detail, inspired
          by culture and tradition.
        </p>
        <button className="learn-more-button">Learn More</button>
      </div>

      {/* Product Grid */}
      <div className="product-grid">
        <div className="product-card">
          <img src={require("../images/food1.jpg")} alt="Product 1" />
          <h3>Handcrafted Tote Bag</h3>
          <p>A unique design to carry your essentials in style.</p>
        </div>
        <div className="product-card">
          <img src={require("../images/food2.jpg")} alt="Product 2" />
          <h3>Custom Greeting Cards</h3>
          <p>Say it with a personal touch, perfect for any occasion.</p>
        </div>
        <div className="product-card">
          <img src={require("../images/food3.jpg")} alt="Product 3" />
          <h3>Handcrafted Journals</h3>
          <p>Capture your thoughts in these beautiful handmade journals.</p>
        </div>
        <div className="product-card">
          <img src={require("../images/food4.jpg")} alt="Product 4" />
          <h3>Wine Bags</h3>
          <p>Elegant wine bags for special celebrations.</p>
        </div>
      </div>
    </div>
  );
}

export default VendorTemplate3;
