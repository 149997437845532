import React, { useState } from 'react';

const ForumForm = ({ addPost }) => {
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (author.trim() && content.trim()) {
      addPost({ author, content });
      setAuthor('');
      setContent('');
    } else {
      alert('Please fill in both fields.');
    }
  };

  return (
    <form className="forum-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Your Name"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
      />
      <textarea
        placeholder="Your Post"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      ></textarea>
      <button type="submit">Post</button>
    </form>
  );
};

export default ForumForm;
