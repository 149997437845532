import React from 'react';
import './YourStore.css'; // Add this CSS for styling
import { Link } from "react-router-dom";
// Dynamically import all images from the folder
const images = require.context('../images', false, /\.(png|jpe?g|svg)$/);

function YourStore() {
  // Map all images into an object for easy access
  const imageMap = images.keys().reduce((acc, path) => {
    const imageName = path.replace('./', '').replace(/\.\w+$/, ''); // Remove './' and file extension
    acc[imageName] = images(path);
    return acc;
  }, {});

  const imageTiles = [
    { name: '', image: imageMap['heb'] }, // Dynamically use images
    { name: '', image: imageMap['cm'] },
    { name: '', image: imageMap['tj'] },
    { name: '', image: imageMap['lafiesta'] },
    { name: '', image: imageMap['el'] },
    { name: '', image: imageMap['alibaba'] },
  ];
  const imageTiles2 = [
    { name: 'Las Americas Latin Market' }, // Dynamically use images
    { name: 'Poco Loco Supermercado' },
    { name: 'La Moreliana Meat Market', image: imageMap['tj'] },
    { name: 'Toro Texano Meat Market', image: imageMap['lafiesta'] },
    { name: 'Eagle African Market', image: imageMap['el'] },
    { name: 'Tj African Market + Kitchen', image: imageMap['alibaba'] },
  ];
  const listItems = [
    'Item 1: Discounted Rates',
    'Item 2: Weekly Offers',
    'Item 3: Exclusive Services',
    'Item 4: Premium Deals',
  ];

  return (
    <div className="your-store-container">
      {/* First Block: Grid of Image Tiles */}
      <div className="image-grid">
  {imageTiles.map((tile, index) => (
    <div key={index} className="tile">
      <img src={tile.image} alt={tile.name} className="tile-image" />
      <span className="tile-name">{tile.name}</span>
    </div>
  ))}
</div>


      {/* Second Block: Tile Containing a List */}
      <div className="image-grid">
        {imageTiles2.map((tile, index) => (
          <div key={index} className="tile">
         
            <span className="tile-name">{tile.name}</span>
          </div>
        ))}
      </div>

      {/* Third Block: Template Tabs */}
      <div className="special-offers-tile">
        <h3>Today's Best Offer – Shop and Save Big!</h3>
        <p><i>HEB Specials: Fresh bakery items 50% off today only! Hurry while supplies last!</i></p>
        <p><i>Central Market: Buy 1 Get 1 Free on organic sauces! Stock up on essentials today</i></p>
        <p><i>Trader Joe's: 20% off on fall-themed produce and pumpkin spice treats! Grab your favorites this season.</i></p>
        
        <button className="offer-button">View Offers</button>
      </div>

      <div className="vendor-template1">
        <h3>Vendor 1</h3>
        <p>Grab exclusive deals and offers available only for today!</p>
        <Link to="/vendor-template1">
          <button className="offer-button">Go To vendor 1</button>
        </Link>
      </div>

      <div className="vendor-template2">
        <h3>Vendor 2</h3>
        <p>Grab exclusive deals and offers available only for today!</p>
        <Link to="/vendor-template2">
          <button className="offer-button">Go To vendor 2</button>
        </Link>
      </div>

      <div className="vendor-template3">
        <h3>Vendor 3</h3>
        <p>Grab exclusive deals and offers available only for today!</p>
        <Link to="/vendor-template3">
          <button className="offer-button">Go To vendor 3</button>
        </Link>
      </div>
    </div>
  );
}

export default YourStore;
