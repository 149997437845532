import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

function ProductCategories({ categories }) {
  const defaultCategories = [
    { name: 'Grocery stores', icon: '🛒', url: '/your-store' },
    { name: 'Boutique', icon: '👜' },
    { name: 'IT Services', icon: '💻' },
    { name: 'Home Help', icon: '🏡' },
    { name: 'Realtor', icon: '🏘️' },
    { name: 'Transportation', icon: '🚗' },
    { name: 'Legal', icon: '⚖️' },
    { name: 'Education', icon: '📚' },
    { name: 'Health', icon: '⚕️' },
    { name: 'Student Services', icon: '🎓' },
    { name: 'Beauty', icon: '💄' },
    { name: 'Tailor', icon: '👗' },
    { name: 'Venues', icon: '🏢' },
    { name: 'Photography', icon: '📷' },
    { name: 'Event Management', icon: '🎉' },
    { name: 'Jewelry', icon: '💍' },
    { name: 'Food Truck', icon: '🚚' },
    { name: 'Restaurants', icon: '🍴' },
  ];

  // Use the provided categories or the default list
  const categoriesToRender = categories || defaultCategories;

  return (
    <div className="new-tile-container-new">
      {categoriesToRender.map((category, index) => (
        <div key={index} className="new-tile">
          {category.url ? (
            <Link to={category.url} style={{ textDecoration: 'none', color: 'inherit' }}>
              <span className="icon-for-new-tile">{category.icon}</span>
              <span>{category.name}</span>
            </Link>
          ) : (
            <>
              <span className="icon-for-new-tile">{category.icon}</span>
              <span>{category.name}</span>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default ProductCategories;