import React, { useEffect, useState } from 'react';

const UserDashboard = () => {
  const [favorites, setFavorites] = useState([]);

  // Load favorite categories from local storage
  useEffect(() => {
    const savedFavorites = JSON.parse(localStorage.getItem('favoriteCategories')) || [];
    setFavorites(savedFavorites);
  }, []);

  return (
    <div className="user-dashboard">
      <h2>Your Favorite Services</h2>
      {favorites.length === 0 ? (
        <p>No favorites yet. Go to the categories and mark your favorites!</p>
      ) : (
        <div className="favorite-list">
          {favorites.map((favorite) => (
            <div key={favorite.id} className="favorite-item">
              <span className="icon">{favorite.icon}</span>
              <span className="name">{favorite.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
