import React from 'react';
import './AboutUs.css';
import TextToSpeech from './TextToSpeech';

function AboutUs() {
  const fullText = `
    Welcome to GoOurHub! At GoOurHub, we believe in the power of connection, diversity, and community. 
    Our platform is a vibrant, all-in-one hub designed to celebrate cultures, connect communities, and 
    make discovering the world around you easier than ever. Whether you're exploring local businesses, 
    discovering international cuisines, or finding the services you need, GoOurHub is your one-stop 
    destination to bring people and cultures together under one roof.
    
    Our vision is simple yet profound: to unite communities and create a space where everyone belongs. 
    From grocery stores to restaurants, boutiques to salons, and countless other services, we make it 
    seamless for you to navigate through the richness of diversity in your city (right now in San Antonio, Texas).
    
    Our Founders: GoOurHub was born out of a shared dream of two tech visionaries from diverse backgrounds 
    who came together with a singular purpose: to bridge gaps and build connections. With over 25 years 
    of combined experience in technology and innovation, they bring expertise, passion, and a deep commitment 
    to creating a platform that celebrates inclusion and unity.
    
    Collaborate with Us! Are you interested in partnering with us or showcasing your business on GoOurHub? 
    We'd love to hear from you! Together, we can create a vibrant community hub that celebrates diversity and connection.
  `;

  return (
    <div className="about-us-container" id="about-us">
      <div className="about-us-logo"></div>

      <h2>About Us</h2>
      <TextToSpeech text={fullText} /> {/* Text-to-Speech button for the entire content */}

      <p>
        Welcome to <span>GoOurHub</span>! At <span>GoOurHub</span>, we believe in the power of connection, diversity, and community. 
        Our platform is a vibrant, all-in-one hub designed to celebrate cultures, connect communities, and make discovering 
        the world around you easier than ever. Whether you're exploring local businesses, discovering international cuisines, 
        or finding the services you need, <span>GoOurHub</span> is your one-stop destination to bring people and cultures 
        together under one roof.
      </p>

      <p>
        Our vision is simple yet profound: to unite communities and create a space where everyone belongs. From grocery 
        stores to restaurants, boutiques to salons, and countless other services, we make it seamless for you to navigate 
        through the richness of diversity in your city (right now in San Antonio, Texas).
      </p>

      <p>
        Our Founders <span>GoOurHub</span> was born out of a shared dream of two tech visionaries from diverse backgrounds 
        who came together with a singular purpose: to bridge gaps and build connections. With over 25 years of combined 
        experience in technology and innovation, they bring expertise, passion, and a deep commitment to creating a platform 
        that celebrates inclusion and unity.
      </p>

      <div className="collaboration-section">
        <h3>Collaborate with Us!</h3>
        <p>
          Are you interested in partnering with us or showcasing your business on <span>GoOurHub</span>? We'd love to hear 
          from you! Together, we can create a vibrant community hub that celebrates diversity and connection.
        </p>
        <p>
          <a href="/contact" className="contact-link">Contact Us</a> today to learn more about how we can collaborate and 
          build something amazing together!
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
