import React from 'react';
import Modal from 'react-modal';
import './SignUp.css'; // Use your updated SignUp styles

Modal.setAppElement('#root'); // Required for accessibility

const SignUpModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Sign Up Modal" className="small-modal">
      <div className="sign-up-container">
        <h2>Sign Up</h2>
        <form>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" name="username" placeholder="Enter your username" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" placeholder="Enter your password" />
          </div>
          <button type="submit" className="primary-button">Sign Up</button>
          <button type="button" onClick={onClose} className="close-button">Close</button>
        </form>
      </div>
    </Modal>
  );
};

export default SignUpModal;
