import './template.css';
const Template = ({ children }) => {
    return (
        <div class="grid-container">
            {children}
        </div>
    );

};


export default Template;