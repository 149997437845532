import React, { useState } from 'react';
import ForumPost from './ForumPost';
import ForumForm from './ForumForm';

const CommunityForum = () => {
  const [posts, setPosts] = useState([
    { id: 1, author: "Your GoOurHub", content: "Welcome to the community forum!" }
  ]);

  // Add a new post
  const addPost = (newPost) => {
    setPosts((prevPosts) => [...prevPosts, { id: Date.now(), ...newPost }]);
  };

  return (
    <div className="forum-container">
      <h2>Community Forum</h2>
      <ForumForm addPost={addPost} />
      <div className="forum-posts">
        {posts.map((post) => (
          <ForumPost key={post.id} author={post.author} content={post.content} />
        ))}
      </div>
    </div>
  );
};

export default CommunityForum;
