import React from 'react';
import './ForumPost.css'; // Optional for styling

const ForumPost = ({ author, content }) => {
  return (
    <div className="forum-post">
      <strong>{author}</strong>
      <p>{content}</p>
    </div>
  );
};

export default ForumPost;
