// LanguageDropdown.js
import React from "react";

function LanguageDropdown({ selectedLanguage, onLanguageChange }) {
  const languages = [
    { value: "english", label: "English" },
    { value: "ethiopian", label: "Ethiopian" },
    { value: "arabic", label: "Arabic" },
    { value: "chinese", label: "Chinese" },
    { value: "spanish", label: "Spanish" },
    { value: "hindi", label: "Hindi" },
    { value: "bengali", label: "Bengali" },
    { value: "swahili", label: "Swahili" },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <label
        htmlFor="language-selector"
        style={{ fontSize: "13px", color: "#FFc107", marginBottom: "4px", marginTop: "5px" }}
      >
        Change Language
      </label>
      <select
        id="language-selector"
        value={selectedLanguage}
        onChange={(e) => onLanguageChange(e.target.value)}
        style={{
            padding: "8px",
            fontSize: "12px",
            borderRadius: "10px", // Corrected to camelCase
            backgroundColor: "#e7deee" // Corrected to camelCase
          }}
          
      >
        {languages.map((lang) => (
          <option key={lang.value} value={lang.value}>
            {lang.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default LanguageDropdown;
