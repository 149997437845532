import React from 'react';
import './American.css'; // Add this CSS for styling
import ProductCategories from './ProductCategories'; // Import ProductCategories

// Dynamically import all images from the folder
const images = require.context('../images', false, /\.(png|jpe?g|svg)$/);

function American() {
  // Map all images into an object for easy access
  const imageMap = images.keys().reduce((acc, path) => {
    const imageName = path.replace('./', '').replace(/\.\w+$/, ''); // Remove './' and file extension
    acc[imageName] = images(path);
    return acc;
  }, {});

  const imageTiles = [
    { name: '', image: imageMap['heb'] },
    { name: '', image: imageMap['cm'] },
    { name: '', image: imageMap['tj'] },
    { name: '', image: imageMap['lafiesta'] },
    { name: '', image: imageMap['el'] },
    { name: 'Walmart' },
    { name: 'Target' },
    { name: 'Whole Foods Market' },
    { name: 'Sprouts Farmers Market' },
    { name: 'Costco Wholesale' },
    { name: 'Sam\'s Club' },
    { name: 'Natural Grocers' },
  
  ];

  const imageTiles2 = [
   
    { name: 'Morton\'s The Steakhouse' },
    { name: 'Southerleigh Fine Food & Brewery' },
    { name: 'Max & Louie\'s New York Diner' },
    { name: 'The Magnolia Pancake Haus' },
    { name: 'The Cheesecake Factory' },
    { name: 'LongHorn Steakhouse' },
    { name: 'Outback Steakhouse' },
    { name: 'Olive Garden' },
    { name: 'Raising Cane\'s Chicken Fingers' },
    { name: 'Pappadeaux Seafood Kitchen' }
  ];
  

  const filteredCategories = [
  
    { name: 'Boutique', icon: '👜' },
    { name: 'Restaurants', icon: '🍴' },
    { name: 'Health', icon: '⚕️' },
    { name: 'Student Services', icon: '🎓' },
    { name: 'Beauty', icon: '💄' },
    { name: 'Tailor', icon: '👗' },
    { name: 'Venues', icon: '🏢' },
    { name: 'Photography', icon: '📷' },
    { name: 'Event Management', icon: '🎉' },
    { name: 'Jewelry', icon: '💍' },
    { name: 'Food Truck', icon: '🚚' },
  ];

  return (
    <div>
      {/* 1st Section: Filtered Product Categories */}
      <div className="product-categories-section">
        <ProductCategories categories={filteredCategories} />
      </div>

      <div className="your-store-container">
        {/* First Block: Grid of Image Tiles */}
        <div className="image-grid">
          {imageTiles.map((tile, index) => (
            <div key={index} className="tile">
              <img src={tile.image} alt={tile.name} className="tile-image" />
              <span className="tile-name">{tile.name}</span>
            </div>
          ))}
        </div>

        {/* Second Block: Tile Containing a List */}
        <div className="image-grid">
          {imageTiles2.map((tile, index) => (
            <div key={index} className="tile">
              <span className="tile-name">{tile.name}</span>
            </div>
          ))}
        </div>

        {/* Third Block: Special Offers */}
        <div className="special-offers-tile">
          <h3>Today's Best Offer – Shop and Save Big!</h3>
          <p><i>HEB Specials: Fresh bakery items 50% off today only! Hurry while supplies last!</i></p>
          <p><i>Central Market: Buy 1 Get 1 Free on organic sauces! Stock up on essentials today.</i></p>
          <p><i>Trader Joe's: 20% off on fall-themed produce and pumpkin spice treats! Grab your favorites this season.</i></p>
          <button className="offer-button">View Offers</button>
        </div>
      </div>
    </div>
  );
}

export default American;
