import React, { useState } from "react";
import logo from "../../logo.png";
import "../../NewTile.css";
import "./header.css";
import SignInModal from "./SignInModal";
import SignUpModal from "./SignUpModal";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { FaMicrophone, FaBars } from "react-icons/fa"; // Import mic and hamburger icon

function Header({ onSearch }) {
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for hamburger menu

  const handleSearchChange = (event) => {
    onSearch(event.target.value);
  };

  const handleMicClick = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Speech recognition not supported. Please use Chrome.");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onstart = () => setIsListening(true);
    recognition.onend = () => setIsListening(false);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      onSearch(transcript);
    };

    recognition.start();
  };

  return (
    <div className="header-container">
      <div className="header-pair-container">
        {/* Logo Section */}
        <div className="logo-section">
          <Link to="/">
            <img src={logo} alt="GoOurHub Logo" className="logo-image" />
          </Link>
          <div>
            <div className="header-buttons">
              <button className="header-button sign-in" onClick={() => setIsSignInModalOpen(true)}>
                <i className="fas fa-user" style={{ marginRight: "0px" }}></i>
                Sign In
              </button>
              <button className="header-button sign-up" onClick={() => setIsSignUpModalOpen(true)}>
                <i className="fas fa-user" style={{ marginRight: "0px" }}></i>
                Sign Up
              </button>
            </div>
          </div>
        </div>

        {/* Social Media Section */}
        <div className="social-media-section">
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61560982405400" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/goourhub/" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Navigation and Search Section */}
      <div className="nav-section">
        <div className="search-container" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <button className="mic-button" onClick={handleMicClick}>
            <FaMicrophone size={12} color={isListening ? "red" : "black"} />
          </button>
          <i className="fas fa-search search-icon"></i>
          <input type="text" placeholder="Search GoOurHub" className="search-bar" onChange={handleSearchChange} />
        </div>

        {/* Hamburger Menu for Mobile View */}
        <div className="hamburger-container">
          <button className="hamburger-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FaBars size={24} color="#e7deee" />
          </button>
          {isMenuOpen && (
            <div className="menu-dropdown">
              <Link to="/" className="menu-item">Home</Link>
              <Link to="/about-us" className="menu-item">About</Link>
              <Link to="/contact" className="menu-item">Contact</Link>
              <Link to="/forum" className="menu-item">Community Forum</Link>
              <Link to="/review-system" className="menu-item">Review and Ratings</Link>
              <Link to="/dashboard" className="menu-item">My Dashboard</Link>
            </div>
          )}
        </div>
      </div>

      {/* Sign In Modal */}
      <SignInModal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />

      {/* Sign Up Modal */}
      <SignUpModal isOpen={isSignUpModalOpen} onClose={() => setIsSignUpModalOpen(false)} />
    </div>
  );
}

export default Header;
