import React, { useState } from 'react';
import ReviewForm from './ReviewForm';
import ReviewList from './ReviewList';

const ReviewSystem = () => {
  const [reviews, setReviews] = useState([]);

  const addReview = (newReview) => {
    setReviews((prevReviews) => [...prevReviews, { id: Date.now(), ...newReview }]);
  };

  return (
    <div className="review-system">
      <h2>Customer Reviews and Ratings</h2>
      <ReviewForm addReview={addReview} />
      <ReviewList reviews={reviews} />
    </div>
  );
};

export default ReviewSystem;
