import React, { useRef } from "react";
import './SliderForMobile.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import 'flag-icons/css/flag-icons.min.css'; // Import flag-icons
import './FlagStyles.css'; // Import custom CSS for flag styles

function SliderForMobile({ countries }) { // Accept countries as props
  const sliderRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const shiftLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -120, behavior: "smooth" });
    }
  };

  const shiftRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 120, behavior: "smooth" });
    }
  };

  return (
    <div className="slider-holder">
      <button className="arrow left" onClick={shiftLeft}>
        &lt;
      </button>
      <div className="slider-container" ref={sliderRef}>
        {countries.map((country, index) => (
          <div
            key={index}
            className="slider-container-tile"
            onClick={() => {
              if (country.url) navigate(country.url); // Navigate if URL exists
            }}
          >
            <span className={`fi fi-${country.flag} flag-icon`}></span>
            <span className="flag-name">{country.people}</span>
          </div>
        ))}
      </div>
      <button className="arrow right" onClick={shiftRight}>
        &gt;
      </button>
    </div>
  );
}

export default SliderForMobile;
