import React, { useState } from 'react';
import './ImprtantLinksContainerOverridde.css';
import { Link } from "react-router-dom";

function ImportantLinks() {
  const [showSubLinks, setShowSubLinks] = useState(false); // State to toggle sub-links

  const links = [
    { name: 'Special Offers', url: '#' },
    { name: 'Community Events', url: 'https://www.sacurrent.com/sanantonio/eventsearch?v=d' },
    { name: 'Community Connect', url: '#' }, // Placeholder link
    { name: 'Online Marketplaces', url: '#' },
  ];

  const subLinks = [
    { name: 'American Indians in Texas', url: 'https://aitscm.org/' },
    { name: 'Anuja SA (Marathi Community)', url: 'https://www.anujasa.com/' },
    { name: 'Bengali Community', url: 'https://sanantoniobcc.org/' },
    { name: 'Gujarati Samaj of San Antonio', url: 'https://www.gujaratisamajofsa.org/' },
    { name: 'India Association of San Antonio', url: 'https://www.indiasa.org/' },
    { name: 'Kannada Sangha San Antonio', url: 'https://www.kks-sa.org/html/NewSite/about.html' },
    { name: 'Kerala Association of San Antonio (Malayalam Community)', url: 'https://www.keralasa.org/' },
    { name: 'Mexican American Unity Council', url: 'https://stmupublichistory.org/lcd/index.php/mexican-american-unity-council/' },
    { name: 'San Antonio African American Community Archive and Museum', url: 'https://www.sanantoniomag.com/local-organizations-to-support-to-further-racial-equality/' },
    { name: 'San Antonio Ethnic Art Society', url: 'https://saethnicartsociety.org/' },
    { name: 'Tamil Community', url: 'https://satamilsangam.org/' },
    { name: 'Urban Indian SA', url: 'https://urbanindiansa.net/' },
  ];

  const popularSites = [
    { name: 'Amazon', url: 'https://www.amazon.com' },
    { name: 'Google', url: 'https://www.google.com' },
    { name: 'YouTube', url: 'https://www.youtube.com' },
    { name: 'ChatGPT', url: 'https://chat.openai.com' },
  ];

  return (
    <div className="important-links-container important-links-container-custom">
      {/* Important Links Section */}
      <div className="important-links-list">
  <h5><b>Important Links Hub</b></h5>
  
  

  <ul className="important-links-container">
    {links.map((link, index) => (
      <li key={index}>
        <a
          href={link.url}
          className="important-link-button"
          target={link.name === 'Community Events' ? '_blank' : '_self'}
          rel={link.name === 'Community Events' ? 'noopener noreferrer' : undefined}
          onClick={(e) => {
            if (link.name === 'Community Connect') {
              e.preventDefault(); // Prevent navigation for "Community Connect"
              setShowSubLinks(!showSubLinks); // Toggle sub-links
            }
          }}
        >
          {link.name}
        </a>
        {link.name === 'Community Connect' && showSubLinks && (
  <ul className="sub-links-container">
    {subLinks.map((subLink, subIndex) => (
      <li key={subIndex} className="sub-link-item">
        <a
          href={subLink.url}
          className="sub-link-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {subLink.name}
        </a>
      </li>
    ))}
  </ul>
)}

      </li>
    ))}
  </ul>
</div>

      {/* Popular Sites Section */}
<div className="popular-sites-list">
  <h5><b>Popular Sites</b></h5>
  <ul className="popular-sites-container">
    {popularSites.map((site, index) => (
      <li key={index}>
        <a
          href={site.url}
          className="popular-site-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {site.name}
        </a>
      </li>
    ))}
  </ul>
</div>
    </div>
  );
}

export default ImportantLinks;
