import React from 'react';

function ServiceCategories() {
  const services = [
   
    { name: 'Gardening', icon: '🌱' },
    { name: 'Catering', icon: '🍲' },
    { name: 'Pet Care', icon: '🐾' },
    { name: 'Child Care', icon: '👶' },
    { name: 'Elderly Care', icon: '🧓' },
    
    
    { name: 'Marketing', icon: '📈' },
    { name: 'Design', icon: '🎨' },
    { name: 'Legal Advice', icon: '⚖️' },
    { name: 'Fitness Training', icon: '🏋️' },
    { name: 'Repair Services', icon: '🔧' },
    { name: 'Transportation', icon: '🚛' },
    { name: 'Tutoring', icon: '📖' },
    { name: 'Consulting', icon: '🤝' },
    { name: 'Personal Shopping', icon: '🛍️' },
    { name: 'Accommodation', icon: '🏠' },
    { name: 'Construction', icon: '🔧' },
    { name: 'Storage', icon: '📦' },
    { name: 'Translation', icon: '🌐' },
    { name: 'Worship Places', icon: '⛪' },
    { name: 'Sports', icon: '🏅' },
    { name: 'Entertainment', icon: '🎭' },
    { name: 'Employment', icon: '💼' },
    { name: 'Financial', icon: '💰' },
    { name: 'Communication', icon: '📞' },
    { name: 'Plumbing', icon: '🚰' },
    { name: 'Electrical', icon: '💡' },
    { name: 'Cleaning', icon: '🧹' },
  ];

  return (
    <div className="new-tile-container">
      {services.map((service, index) => (
        <div key={index} className="new-tile">
          <span className='icon-for-new-tile'>{service.icon}</span>
          <span>{service.name}</span>     
        </div>
      ))}
    </div>
  );
}

export default ServiceCategories;
