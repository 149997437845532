import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // Load translations via HTTP
  .use(LanguageDetector) // Detect language
  .use(initReactI18next) // Bind to React
  .init({
    supportedLngs: ['en', 'es'], // List of supported languages
    fallbackLng: 'en', // Default language if detection fails
    debug: true, // Debugging logs in development

    interpolation: {
      escapeValue: false // React already escapes values
    },

    backend: {
      loadPath: '/locales/{{lng}}/translation.json' // Translation files path
    }
  });

export default i18n;
